import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// ship of fools
import {Container, Tab, Tabs, Card, Row, Col, Image} from "react-bootstrap";

export const Home = () => {

  const [selectedKey, setSelectedKey] = useState(window.location.hash
    ? window.location.hash.substring(1)
    : "home");


  const handleSelect = (key) => {
    setSelectedKey(key);
    window.location.hash = "#" + key;
  }

  const location = useLocation();

  useEffect(() => {
    setSelectedKey(location.hash ? location.hash.substring(1) : "home");
  },[location]);

  return (
    <Container float>

    <Tabs
        // defaultActiveKey="home"
        activeKey={selectedKey}
        className="mt-5 tab-block-c"
        onSelect={handleSelect} 
      >
        <Tab.Pane eventKey="home" title="Home" className="tab-c">
          <Card className="card-c">
            <Row>
              <Col md={4} className="col-c">
                <Image className="myimg" src="../me3.jpg" rounded></Image>
                {/* <p className="capt-c">Me in Carmel, CA</p> */}
              </Col>
              <Col md={8} className="col-c">
               <p><h1 className="inline">About Justus</h1></p>

                {/* <h5>Employment</h5> */}
                <p>I’m a software engineering intern at 
                  <a href="https://www.nasa.gov/software-robotics-and-simulation-division/robotic-systems-technology-branch/"> NASA Robotics Systems Technology Branch (ER4)</a> at 
                  the <a href="https://www.nasa.gov/johnson/">NASA Johnson Space Center</a>
                  . 
                  </p>

                {/* <p>I also work on motor controller software at <a href="https://www.thethriftybot.com/">The Thrifty Bot</a>. Yes, I am actively overemployed. More information on my employment history is available on my resume.</p> */}
                <p>More information about my employment history is <a href="#work">here</a>.</p>

                {/* <h5>Education</h5> */}
                <p>I’m an honors computer science student at <a href="https://engineering.tamu.edu/cse/index.html">Texas A&M University</a> (incoming freshman).</p>

                <p>I graduated summa cum laude from The Woodlands High School, and I’m a FIRST alum.</p> 

                <p>I spend <s>most</s> <i>all</i> of my free time working on programming projects.</p>
              </Col>
            </Row>
          </Card>
        </Tab.Pane>

        <Tab eventKey="work" title="Work History" className="tab-c">
          <Card className="card-c">
          <Row>
            
              <Col md={4} className="col-c">
              {/* <Col md={4} className="col-c hide-if-small"> */}
                {/* <Row>
                  <Image className="icon" src="../nasa.png" rounded></Image>
                </Row> */}
                {/* <Row>
                  <Image className="icon" src="../ttb.jpg" rounded></Image>
                </Row> */}
                {/* <Row>
                  <Image className="icon" src="../tb.jpg" rounded></Image>
                </Row> */}
                  <Row>
                  <Image className="myimg vctr" src="../nasa.png" rounded></Image>
                </Row>
              </Col>

              <Col md={8} className="col-c">
               <p><h1 className="inline">Work History</h1></p>

                <p>
                  <b>Software Engineering Intern</b> (6/2024–8/2024) <br></br>
                  <a href="https://www.nasa.gov/software-robotics-and-simulation-division/robotic-systems-technology-branch/"> NASA Robotics Systems Technology Branch</a> 
                </p>

                <p>
                  <b>Software Engineering Intern</b> (5/2024–8/2024) <br></br>
                  <a href="https://www.thethriftybot.com/">The Thrifty Bot L.L.C.</a>
                </p>


                <p>
                  <b>Software Engineering Intern</b> (8/2022–5/2024) <br></br>
                  {/* <a href="https://www.webcreek.com/">WebCreek Ltd.</a> and <a href="https://www.trainbeyond.com/">TrainBeyond L.L.C.</a> */}
                   <a href="https://www.trainbeyond.com/">TrainBeyond L.L.C.</a> (subsidiary of <a href="https://www.webcreek.com/">WebCreek Ltd.</a>)
                </p>

                <p>
                  <b>Software Leadership</b> (7/2021–5/2024) <br></br>
                  {/* <a href="https://www.webcreek.com/">WebCreek Ltd.</a> and <a href="https://www.trainbeyond.com/">TrainBeyond L.L.C.</a> */}
                   <a href="https://www.texastorque.org/">Texas Torque</a> (FIRST Robotics Competition team 1477)
                </p>




              </Col>
            </Row>
          </Card>
        </Tab>

        <Tab eventKey="contact" title="Contact" className="tab-c">
          <Card className="card-c">
          <Row>
              <Col md={4} className="col-c">
                <Image className="myimg" src="../jsc1.webp" rounded></Image>
                {/* <p className="capt-c">Johnson Space Center</p> */}
              </Col>
              <Col md={8} className="col-c">
               <p><h1 className="inline">Contact</h1></p>
               <p>Feel free to email me at <a href="">jus@justusl.com</a>.</p>
               <p>My LinkedIn profile is <a href="https://www.linkedin.com/in/justus">linkedin.com/in/justus</a>.</p>
               <p>My GitHub profile is <a href="https://www.github.com/Juicestus">github.com/juicestus</a>.</p>
              </Col>
            </Row>
          </Card>
        </Tab>
      
      </Tabs>

   


    </Container>
  );
}

// export default Home;
